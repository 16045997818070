import React from "react";
import { Gigs_allMarkdownRemark_nodes_frontmatter_other_bands } from "../../pages/__generated__/Gigs";
import { OtherBand } from "./OtherBand";

export interface IOtherBandsProps {
	bands: (Gigs_allMarkdownRemark_nodes_frontmatter_other_bands | null)[];
}

export const OtherBands: React.FC<IOtherBandsProps> = (props) => {
	const otherBands = props.bands.filter(x => !!x) as Gigs_allMarkdownRemark_nodes_frontmatter_other_bands[];

	return (
		<>
			With: {otherBands.map((band, index) => <OtherBand key={band.name!} band={band} lastBand={index < otherBands.length - 1} />)}
		</>
	);
};