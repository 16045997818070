import styled from "styled-components";
import { marginPercent, breakpoint } from "../Layout";
import { useWindowSize } from "../../hooks/useWindowSize";
import { CloudinaryImage } from "../../utils/CloudinaryImage";

export const maxCardWidthPercent = (100 - marginPercent) / 2;

export const CardDeck = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	flex-wrap: wrap;
	justify-content: space-between;
`;

export const Card = styled.div`
	background: hsl(240, 0%, 19%);
	margin-bottom: 1em;
	overflow: hidden;
	border-radius: 15px;
	width: 100%;
	
	@media(min-width: 576px) {
		width: ${maxCardWidthPercent}%;
	}
`;

export const CardImage = styled.img`
	margin-bottom: 0;
	width: 100%;
`;

export const CardHeader = styled.div`
	margin: 0.5em;
`;

export const CardTitle = styled.h3`
	margin: 0;
	font-weight: 100;
	font-family: "Roboto Thin";
`;

export const CardSubtitle = styled.span`
	font-weight: 300;
	margin: 0;
	font-family: "Roboto Light";
	display: block;
`;

export const CardSubSubtitle = styled.span`
	font-weight: 300;
	margin: 0;
	font-size: 14px;
	font-family: "Roboto Light";
	display: block;
`;

export const CardBody = styled.div`
	margin: 0.5em;
`;

export const CardFooter = styled.div`
	margin: 1em 0 0 0;
	padding: 0;
	background: hsl(240, 0%, 26%);
	display: flex;
	flex-direction: row;
	justify-content: space-around;
`;

export const CardFooterLink = styled.a`
	font-family: "Roboto";
	font-weight: 500;
	text-transform: uppercase;
	transition-property: color, background-color;
	width: 100%;
	height: 100%;
	display: block;
	text-align: center;
	padding: 1em 0.5em 1em 0.5em;

	:hover {
		background-color: hsl(240, 0%, 34%);
	}
`;

export const getCardUrl = (originalUrl: string) => {
	const { width, containerWidth } = useWindowSize();

	const halfWidth = Math.round((maxCardWidthPercent / 100) * containerWidth);
	const localMaxWidth = (width > breakpoint) ? halfWidth : containerWidth;

	return CloudinaryImage.autoUrl(originalUrl, localMaxWidth);
};