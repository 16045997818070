import React from "react";
import { Gigs_allMarkdownRemark_nodes_frontmatter_other_bands } from "../../pages/__generated__/Gigs";

export interface IOtherBandProps {
	band: Gigs_allMarkdownRemark_nodes_frontmatter_other_bands;
	lastBand: boolean;
}

export const OtherBand: React.FC<IOtherBandProps> = (props) => {
	const getBandName = (band: Gigs_allMarkdownRemark_nodes_frontmatter_other_bands) => {
		if (band.facebook_link) {
			return (<a href={band.facebook_link}>{band.name}</a>);
		}
		return band.name;
	};

	return (<>{getBandName(props.band)}{props.lastBand && ", "}</>);
};