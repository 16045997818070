import { graphql, PageProps } from "gatsby";
import React from "react";
import { CardDeck } from "../components/Card/Card";
import { Gig } from "../components/Gig/Gig";
import Layout, { MainContainerDiv, PageTitle } from "../components/Layout";
import { Gigs } from "./__generated__/Gigs";

export default (props: PageProps<Gigs>) => {
	const nodes = props.data.allMarkdownRemark.nodes.filter((node) => {
		const date = new Date(node.frontmatter!.date!);
		return (date.valueOf() > Date.now());
	});

	return (
		<Layout title="Live">
			<MainContainerDiv>
				<PageTitle>Live</PageTitle>
				{nodes.length > 0 && <CardDeck>
					{nodes.map((node, index) => <Gig key={index} gig={node.frontmatter!} lastGig={index === nodes.length - 1} />)}
				</CardDeck>}
				{nodes.length === 0 && <p>
					No live dates are currently scheduled. Check back often for announcements!
			</p>}
			</MainContainerDiv>
		</Layout>
	);
};

export const pageQuery = graphql`
	query Gigs {
		allMarkdownRemark(
			filter: {fileAbsolutePath: {regex: "/data\\/gigs\/.*md/"}}
			sort: {fields: frontmatter___date, order: ASC}
		) {
			nodes {
				frontmatter {
					venue {
						name
						address
						city
						google_maps
					}
					date
					formattedDate: date(formatString: "dddd, Do MMMM YYYY")
					poster
					facebook_event_link
					tickets {
						link
						price
					}
					other_bands {
						name
						facebook_link
					}
				}
			}
		}
	}
`;