import React from "react";
import { Gigs_allMarkdownRemark_nodes_frontmatter } from "../../pages/__generated__/Gigs";
import { Card, CardBody, CardFooter, CardFooterLink, CardHeader, CardImage, CardSubSubtitle, CardSubtitle, CardTitle, getCardUrl } from "../Card/Card";
import { OtherBands } from "./OtherBands";

export interface IGigProps {
	gig: Gigs_allMarkdownRemark_nodes_frontmatter;
	lastGig: boolean;
}

export const Gig: React.FC<IGigProps> = ({ gig }) => {
	return (
		<Card>
			<CardImage src={getCardUrl(gig.poster!)} />
			<CardHeader>
				<CardTitle>{gig.venue!.city!}</CardTitle>
				<CardSubtitle>{gig.venue?.name}</CardSubtitle>
				<CardSubSubtitle>{gig.formattedDate}</CardSubSubtitle>
			</CardHeader>
			<CardBody>
				<OtherBands bands={gig.other_bands!} />
			</CardBody>
			<CardFooter>
				<CardFooterLink href={gig.tickets!.link!}>Tickets</CardFooterLink>
				<CardFooterLink href={gig.facebook_event_link!}>Facebook</CardFooterLink>
			</CardFooter>

		</Card>
	);
};
